$black: #333;
$green: rgb(139, 183, 63);
$red: #e30745;

$blue: #5677C3;

.container-out.pvcontent {
    min-height: 80vh;
}
h1.title {
    color: $green;
    font-weight: 600;
}
.logo {
    display: flex;
    align-items: center;
    a:first-of-type img {
        margin-right: 1rem;
    }
    a:last-of-type img {
        width: 150px;
        position: relative;
        top: -2px;
    }
}

.pvheader ul.menu {
    margin-top: 29px;
}


.pv-video {
    width: 610px;
    padding: 0.5rem;
}

.pv-video-blk {
    width: 335px;
}

.page-home {
    .more-link {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 0.5rem;
    }
    
    h3.title {
        font-weight: 600;
        color: darken($green, 5%);    
    }
    
    .tab_sidebar_list {
        position: static;
    }
    h3.title b {
        background-color: $green;
    }
    .nextepisode-heading {
        display: flex;
        align-items: center;
        font-family: "Oswald", sans-serif;        
        font-size: 20px;
        text-transform: uppercase;
        color: white;
        background-color: $black;
        padding: 0.25rem 1rem;
        margin-bottom: 0;
        &:before {
            content: '';
            display: inline-block;
            background-image: url('../img/podcast.png');
            background-size: cover;
            height: 25px;
            width: 25px;
            margin-right: 0.5rem;
        }
    }
    .nextepisode {    
        h3 {
            font-size: 18px;
            line-height: 1.25;
            // strong {
            //     color: $green;                
            // }
        }
    }
    .region-sidebar-home-player {
        margin-bottom: 1rem;
    }
    #block-block-11 .content {
        padding: 1rem;
        background-color: lighten(lightgray, 15%)!important;
        position: relative;
        
        p a.signup-button {
            display: block;
            width: 95%;
            text-align: center;
            margin: 1.25rem 0 1.25rem 0;
            padding: 0.25rem 0.5rem;
            background-color: $red;
            &:hover {
                background-color: darken($red, 5%);
                color: white;
            }
        }
        font-size: 16px;  
        p {
            text-align: left;
            line-height: 1.35;
        }
        p strong {
            font-size: 16px;
            text-transform: uppercase;
            text-align: left;
            
        }

        p.title {
            font-size: 28px;
            margin-top: 3rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid lighten($black, 40%);
        }
    }
}
.footer {
    border: 0;
}

// Upcoming sidebar

.page-home {
    #tab_menu {
        border-bottom: 0;
        margin-bottom: 0.5rem;
        li a {
            line-height: 1.5;
        }
        li.current {
            background-color: lighten($black, 30%);
        }
    }

    .tab_sidebar_list .views-row {
        background-color: white;
        margin-bottom: 0.5rem;
        &:hover {
            background-color: none;
        }
    }

    


    .view-display-id-upcoming_guests .view-content .views-row {
        height: 100%;
        overflow: auto;
        padding: 0;
        padding-right: 0.25rem;
        margin: 0.5rem 0 1rem 0; 
        &:hover {
           background-color: lighten(lightgray, 15%);
           cursor: default;
        }
        .views-field-field-speaker-image {
            float: left;
            margin-right: 0.5rem;
            img {
                width: 85px;
            }
        }
        > a {
            display: block;
            font-weight: bold;
            font-size: 18px;
            margin: 1rem 0 0.5rem 0;
        }
        .views-field-field-speaker-firm {
            font-size: 12px;
            line-height: 1.4;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

.page-guests {
    .view-speakers {
        margin-left: -10px;
        .view-content {
            display: flex;
            flex-wrap: wrap;
        }
        .views-row {
            display: flex;
            // align-items: center;
            flex-direction: column;
            width: 150px;
            margin: 0 1rem 2.5rem 1rem;
            &:hover {
                > a {
                    background-color: lighten($green, 10%);
                    color: darken($green, 15%);                 
                }    
            }
            img {
                box-shadow: 0 0 1px rgba($black, 0.2);
                border-radius: 2px;
            }
            > a {
                background-color: $green;
                padding: 0.25rem 0.5rem;
                color: white; 
                font-size: 16px;
                font-weight: 600;
            }
            .views-field-field-speaker-firm {
                font-weight: 600;
                padding-top: 0.25rem;
                padding-left: 0.5rem;
            }
        }
    }
}
.nonmember-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nonmember-image {
    background-image: url('../img/video.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 150px;
    height: 70px;
}

.nonmember-message--interior {
    background-image: url('../img/nonmember-video.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 250px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    p { 
        font-size: 18px;
        line-height: 1.25;
        margin-bottom: 0.5rem;
    }
}

.view-topics {
    .field-content { 
        display: inline-block;
        font-size: 18px;
        margin-bottom: 0.5rem;
        a { margin-right: 0.5rem;}
    }
}

.topic-alpha {
    background-color: $green;
    padding: 0.25rem 0.75rem 0.25rem 0.5rem;
    font-size: 20px;
    font-weight: 600;
    margin: 1rem 0 0.75rem 0;
    max-width: 200px;
    color: white;
}
.topic-nav-wrapper {
 position: relative;
 
}
.topic-nav {
    position: sticky;
    top: 20px;
    display: flex;
    flex-direction: column;
    background-color: lighten(lightgray, 15%);
    padding: 1rem;
    a {
        display: inline-block;
        padding-bottom: 0.75rem;
        border-bottom: 1px solid lighten(lightgray, 7%);
        margin-bottom: 0.75rem;
        font-size: 20px;   
        font-weight: 600;     
    }
}

.block h4.topic-nav-heading {
    position: sticky;
    top: 20px;
    font-weight: 600;
    font-size: 20px;
    background-color: white;
    color: $black;
}

.recent-video .views-row {
    position: relative;
    &:hover {
        cursor: pointer;
        .recent-video-info {
            display: block;
            position: absolute;
        }
        .views-field-field-teaserimage {
            width: 302px;
            height: 167px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                width: inherit;
                height: inherit;
                background-color: rgba($black, 0.7);
            }
        }
    }
    .views-field-title {
        background-color: $green;
        padding: 0.1rem 0.5rem;
        a { 
            color: white;
            font-weight: 600;
        }
    }
}

.recent-video-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
        flex: 0 0 49.5%;
        margin-bottom: 1rem;

    }
}

.recent-video-info {
    display: none;
    color: white;
    font-weight: 600;
    padding: 0.5rem;
    .views-label {
        display: block;
        color: $green;
    }
    &.views-field-field-topics {
        top: 0;
    }
    &.views-field-field-speakers {
        top: 80px;
    }
}